import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(() => {});
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: '医修保',
    },
  },
  {
    path: '/regis',
    name: 'REGISTER',
    component: () => import('@/views/Regis.vue'),
    meta: {
      title: '快速注册',
    },
  },
  {
    path: '/features',
    name: 'HOME_FEATURES',
    component: () => import('@/views/officalPages/features.vue'),
    meta: {
      title: '功能介绍',
    },
  },
  {
    path: '/about',
    name: 'HOME_ABOUT_US',
    component: () => import('@/views/officalPages/about.vue'),
    meta: {
      title: '关于我们',
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
