import Vue from 'vue';
import toast from './toast.vue';

const Constructor = Vue.extend(toast);
let instance;
const Toast = (option) => {
  if (!instance) {
    instance = new Constructor();
    instance.$mount();
    document.body.appendChild(instance.$el);
  }
  option = typeof option === 'string' ? { content: option } : option;
  const defaultOption = {
    content: '',
    delay: 1500,
    type: 'info',
  };
  for (const key in defaultOption) instance[key] = option[key] || defaultOption[key];
  return instance.open();
};

const types = ['success', 'error', 'warn', 'info'];
types.forEach((type) => (Toast[type] = (content) => new Toast({ content, type })));

export default (Vue) => (Vue.prototype.$toast = Toast);
