<template>
  <transition name="scale">
    <div v-show="visible" class="jr-toast">
      <div class="jr-toast_content">
        <div class="icon_box">
          <i :class="['el-icon-warning', 'icon_ele']"></i>
        </div>
        <p>{{ content }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    content: String,
    type: String,
    delay: String,
    icon: String,
  },
  data () {
    return {
      timer: null,
      visible: false,
    };
  },
  methods: {
    open () {
      const { timer, delay, close } = this;
      if (timer) clearTimeout(timer);
      this.visible = true;
      this.timer = setTimeout(close, delay);
      return close;
    },
    close () {
      clearTimeout(this.timer);
      this.timer = null;
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.jr-toast {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.jr-toast_content {
  padding: 10px 20px;
  color: #fff;
  word-break: break-all;
  background-color: rgba(0, 0, 0, .7);
  border-radius: 10px;
  text-align: center;
  .icon_box {
    width: 100px;
    height: 100px;
    display: inline-block;
    padding-top: 20px;
    .icon_ele {
      font-size: 80px;
    }
  }
}

.scale-enter-active, .scale-leave-active {
  transition: transform .2s;
}

.scale-enter, .scale-leave-to {
  transform: scale(0)
}
</style>
