import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import Toast from './components/Toast';
import {
  Button,
  Form,
  FormItem,
  Row,
  Col,
  Icon,
  Drawer,
  Tabs,
  TabPane,
  Input,
  Option,
  Checkbox,
  Collapse,
  CollapseItem,
  Select,
  MessageBox,
  Menu,
  Submenu,
  MenuItem,
  RadioGroup,
  RadioButton,
} from 'element-ui';
import '@/assets/iconfont/iconfont.css';
import '../node_modules/wowjs/css/libs/animate.css';
import 'animate.css';

// import 'element-ui/lib/theme-chalk/base.css';
// import 'element-ui/lib/theme-chalk/collapse-transition.css';
// 移动端复制
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import 'swiper/swiper-bundle.css';

Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Icon);
Vue.use(Drawer);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Toast);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.component(CollapseTransition.name, CollapseTransition);

Vue.config.productionTip = false;
Vue.prototype.$totast = MessageBox;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
